import React from 'react'
import DashboardLayout from '../../dashboardlayout/DashboardLayout'

function Getsupport() {
  return (
    <DashboardLayout>
    <div><h1 className='text-center font-bold'>Getsupport</h1></div>
    </DashboardLayout>
  )
}

export default Getsupport